function setUpCountDown(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpCountDown(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('CountDown', {
        interval: null,
        countDownDate: null,
        $days: null,
        $hours: null,
        $minutes: null,
        $seconds: null,

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set the countdown date
            self.countDownDate = new Date(self.$el.data('countdownTo'))
                .getTime();

            // Get elements
            self.$days = self.$el.find('.js-countdown__days');
            self.$hours = self.$el.find('.js-countdown__hours');
            self.$minutes = self.$el.find('.js-countdown__minutes');
            self.$seconds = self.$el.find('.js-countdown__seconds');

            // Update the countdown every second
            self.interval = setInterval(function() {
                self.updateCountDown();
            }, 1000);
        },

        updateCountDown: function() {
            // Save a reference to the controller
            var self = this;

            // Get current timestamp
            var now = new Date().getTime();

            // Find the distance between now an the count down date
            var distance = self.countDownDate - now;

            // Check if it's now
            if (distance < 0) {
                self.$days.text(0);
                self.$hours.text(0);
                self.$minutes.text(0);
                self.$seconds.text(0);
                clearInterval(self.interval);
                return;
            }

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Set time
            self.$days.text(days);
            self.$hours.text(hours);
            self.$minutes.text(minutes);
            self.$seconds.text(seconds);
        }
    });
}

setUpCountDown(window.FAB, window);
